import openSocket from "socket.io-client";
import { v4 as uuid } from 'uuid';
import config, { turnServerUrl } from "../config";
import P2pDataAdapter from "./p2pAdapter";

const mediasoupClient = require("mediasoup-client");
const ASPECT_RATIO = config.aspectRatios;
const peerToBitrateMapping = config.peerToBitrateMapping;

const WEBCAM_SIMULCAST_ENCODINGS =
  [
    { scaleResolutionDownBy: 4, maxBitrate: 500000 },
    { scaleResolutionDownBy: 2, maxBitrate: 900000 },
    { scaleResolutionDownBy: 1, maxBitrate: 2500000 }
  ];

const peerToWidthMapping = {
  "1": null,
  "2": null,
  "3": null,
  "4": null,
  "5": null,
  "6": null,
  "7": null,
  "8": 400,
  "9": 320,
  "10": 320
}

export default class CommunicationAdapter {
  constructor(backendUrl, room, metadata, producerParams, callback, webrtcType, simulcast, isRecordingPeer) {
    this.simulcast = simulcast;
    this.webrtcType = webrtcType || "sfu";
    this.aspectRatio = ASPECT_RATIO;
    this.turnCreds = {
      username: "username",
      password: "password"
    }
    this.botDataProducer = null;
    this.consumerTransport = undefined
    this.producerTransport = undefined
    this.audioTransmitting = false;
    this.filters = {};
    this.brightness = {};
    this.videoElProps = {};
    this.socket = undefined
    this.peerMediaHandler = undefined
    this.room = room;
    this.metadata = metadata;
    this.mediaProducers = [];
    this.mediaConsumers = [];
    this.producerParams = producerParams; // {audio: true, video: true}...
    this.callback = callback || (() => { });
    try {
      this.webRTCDevice = new mediasoupClient.Device();
    } catch (err) {
      console.log(err);
      console.log("error while loading mediasoup client device");
      // alert("Failed to load WebRTCDevice", err);
    }
    this.socket = openSocket(`${backendUrl}`, { transport: ['websocket'], query:`session_id=${room}` });
    this.p2pAdapter = new P2pDataAdapter(this.socket, callback, producerParams, ASPECT_RATIO, room);
    this.socket.on("disconnect", () => {
      this.callback({
        type: "signalDisconnected",
        data: {
          videos: Object.keys(this.videoElProps).map(x => ({
            constraints: this.videoElProps[x].constraints,
            stream: this.videoElProps[x].videoEl.srcObject,
            type: this.videoElProps[x].type
          }))
        }
      })
    })
    this.socket.on('connect_error', () => {
      this.callback({
        type: "signalDisconnected",
        data: {
          videos: Object.keys(this.videoElProps).map(x => ({
            constraints: this.videoElProps[x].constraints,
            stream: this.videoElProps[x].videoEl.srcObject,
            type: this.videoElProps[x].type
          }))
        }
      })
    });
    this.socket.on("connect", () => {
      this.socket.emit("initialize", {
        room,
        metadata,
        isRecordingPeer
      })
    })

    this.socket.on("unauthorized", (data) => this.callback({
      type: "unauthorized",
      data: {
        message: data.message
      }
    }))
    this.socket.on("identityAck", this.identityReceived.bind(this))
    this.socket.on("createdTransports", this.createdTransports.bind(this));
    this.socket.on("consumeMedia", this.consumeMedia.bind(this));
    this.socket.on("destroyMedia", this.destroyMedia.bind(this));
    this.socket.on("peerDisconnect", (data) => {
      // this.mediaConsumers.filter(x => x.peerId === data.peerId).map(x => {
      // if (x.isDesktop) {
      // the left peer was sharing video, now we can share the right one
      // this.normalizeOutgoingVideos();
      // }
      // })
      this.mediaConsumers = this.mediaConsumers.filter(x => x.peerId !== data.peerId);
      this.consolidateOutgoingVideoResolutions()
      this.callback({
        type: "disconnect",
        data
      })
    })

    this.socket.on("metaEvent", (data) => this.callback({
      type: data.type,
      data
    }))

    this.socket.on("producerScore", this.producerScoreHandler.bind(this))
    this.socket.on("consumerScaore", this.consumerScoreHandler.bind(this))
  }

  consumerScoreHandler({ score, assignedId }) {
    // if (score < 2) {
    //   this.socket.emit("toggleIncomingMedia", {
    //     type: "pause",
    //     kind: "video",
    //     assignedId
    //   });
    // } else {
    //   this.socket.emit("toggleIncomingMedia", {
    //     type: "resume",
    //     kind: "video",
    //     assignedId
    //   });
    // }
  }

  producerScoreHandler({ scores, assignedId }) {
    const score = scores.reduce(function (avg, { score }, _, { length }) {
      return avg + score / length;
    }, 0);
    this.callback({
      type: "producerScore",
      data: {
        assignedId,
        score
      }
    })
    const mediaProducer = this.mediaProducers.find(x => x.assignedId === assignedId);
    const producer = mediaProducer.producer;
    if (producer) {
      if (score < 4) {
        producer.setMaxSpatialLayer(1)
      } else if (score < 9) {
        producer.setMaxSpatialLayer(2)
      } else {
        producer.setMaxSpatialLayer(3)
      }
    }
  }

  _adaptiveEnabled() {
    return config.options.adaptive
  }

  _getIceServers() {
    return config.options.dontuseturn ? [] : [{
      username: "",
      password: "",
      urls: `stun:${turnServerUrl}`
    }, {
      username: this.turnCreds.username,
      credential: this.turnCreds.password,
      urls: `turn:${turnServerUrl}`
    }]

  }

  sendLayoutToRecorder(props) {
    this.socket.emit("intimateRecordingPeer", props)
  }

  pauseConsumer({ assignedId }) {
    this.mediaConsumers.map(mediaConsumer => {
      if (mediaConsumer.assignedId === assignedId) {
        mediaConsumer.consumer.pause();
      }
    })
  }

  resumeConsumer({ assignedId }) {
    this.mediaConsumers.map(mediaConsumer => {
      if (mediaConsumer.assignedId === assignedId) {
        mediaConsumer.consumer.resume();
      }
    })
  }

  pauseAllConsumers() {
    this.mediaConsumers.map(mediaConsumer => {
      mediaConsumer.consumer.pause();
    })
    this.socket.emit("toggleIncomingMedia", {
      type: "pause",
      kind: "video"
    })
  }

  resumeAllConsumers() {
    this.mediaConsumers.map(mediaConsumer => {
      mediaConsumer.consumer.resume();
    })
    this.socket.emit("toggleIncomingMedia", {
      type: "resume",
      kind: "video"
    })
  }

  disconnect() {
    this?.p2pAdapter?.destroy();
    this.socket.disconnect();
    if (this.producerTransport)
      this.producerTransport.close();
    if (this.consumerTransport)
      this.consumerTransport.close();
    this.producerTransport = null;
    this.consumerTransport = null;
  }

  emit(msg, socketId) {
    this.socket.emit("metaEvent", {
      ...msg,
      to: socketId
    })
  }

  muteUser(socketId) {
    this.emit({
      type: "muteUser"
    }, socketId)
  }

  muteToggled(isMuted) {
    this.socket.emit("toggleAudioMute", {
      audioMuted: isMuted
    });
  };

  muteAll() {
    this.emit({
      type: "muteUser"
    })
  };

  async identityReceived(data) {
    this.id = data.id;
    if (data.forceSfu) {
      this.webrtcType = "sfu";
    }
    this.callback({
      type: "setname",
      data: {
        name: data.name
      }
    })

    this.callback({
      type: "identity",
      data: {
        id: data.id,
        broadcaster: data.broadcaster,
        peers: data.peers
      }
    })

    this.turnCreds = data.turnCreds;
    if (this.webrtcType === "p2p") {
      this.p2pAdapter.identityReceived(data, this._getIceServers());
      return;
    }

    try {
      await this.webRTCDevice.load({
        routerRtpCapabilities: data.roomRTPCapabilities
      });
    } catch (e) {
      console.log(e);
      console.log(data.roomRTPCapabilities);
    }
    this.socket.emit("createTransports", {
      numStreams: this.webRTCDevice.sctpCapabilities.numStreams,
      rtpCapabilities: this.webRTCDevice.rtpCapabilities
    })
  }

  async createdTransports(transports) {
    console.log('_DEBUG_ transports', transports);
    console.log('_DEBUG_ _getIceServers', this._getIceServers());
    this.producerTransport = await this.webRTCDevice.createSendTransport(
      {
        id: transports.producer.id,
        iceParameters: transports.producer.iceParameters,
        iceCandidates: transports.producer.iceCandidates,
        dtlsParameters: transports.producer.dtlsParameters,
        sctpParameters: transports.producer.sctpParameters,
        iceServers: this._getIceServers()
      });
    this.consumerTransport = await this.webRTCDevice.createRecvTransport(
      {
        id: transports.consumer.id,
        iceParameters: transports.consumer.iceParameters,
        iceCandidates: transports.consumer.iceCandidates,
        dtlsParameters: transports.consumer.dtlsParameters,
        sctpParameters: transports.consumer.sctpParameters,
        iceServers: this._getIceServers()
      });
    const self = this;
    this.consumerTransport.on('connect', async function ({ dtlsParameters }, callback, errback) {
      self.socket.emit('connectTransport', { direction: self.consumerTransport.direction, dtlsParameters: dtlsParameters })
      callback();
    })

    this.producerTransport.on('connect', async function ({ dtlsParameters }, callback, errback) {
      self.socket.emit('connectTransport', { direction: self.producerTransport.direction, dtlsParameters: dtlsParameters })
      callback();
    })

    this.producerTransport.on(
      "producedata",
      async (
        { sctpStreamParameters, label, protocol, appData },
        callback,
        errback) => {
        // Send dataProducer data to server
        self.socket.emit('produceData', {
          producerOptions: {
            sctpStreamParameters, label, protocol, appData
          }
        }, function (params) {
          callback({ id: params.id });
        });
      }
    );
    this.producerTransport.on('produce', async function ({ kind, rtpParameters, appData }, callback, errback) {
      //Send producer data to server
      self.socket.emit('produceMedia', {
        producerOptions: {
          kind: kind,
          rtpParameters: rtpParameters,
          appData: appData
        }
      }, function (params) {
        self.mediaProducers = self.mediaProducers.map(producerProps => {
          if (producerProps.assignedId === params.assignedId) {
            return {
              ...producerProps,
              id: params.id
            }
          }
          return producerProps;
        })
        callback({ id: params.id });
      })
    })

    if (transports.botDataChannel) {
      self.consumerTransport.consumeData(transports.botDataChannel)
        .then((newConsumer) => {
          this.botDataChannel = newConsumer;
          this.botDataChannel.on("message", data => {
            this.callback({
              type: "botmessage",
              data: data
            })
          });
        })
    }
    if (this.producerParams.audio) {
      // this.createAudioProducer(
      //   { audio: false }
      // );
    } else {
      const self = this;
      self.muteToggled(true);
    }
    this.createBotDatachannel();
    this.callback({
      type: "transportsCreated"
    })
  }

  async createBotDatachannel() {
    const producer = await this.producerTransport.produceData({
      ordered: false,
      maxRetransmits: 0,
      label: "bot",
      appData: { source: "bot" }
    });
    this.botDataProducer = producer;
  }

  consolidateIncomingVideos(assignedIdToLayerMapping) {
    if (!this.simulcast) {
      return;
    }
    this.socket.emit("changeLayerConfiguration", {
      assignedIdToLayerMapping
    })
  }

  sendViaBotDataChannel(msg) {
    if (!this.botDataProducer || this.botDataProducer.readyState !== "open") {
      console.warn("Data not sent, datachannel not open yet");
      return;
    }
    if (!msg || msg.length === 0) {
      console.warn("No data to send");
      return;
    }
    this.botDataProducer.send(msg)
  }

  destroyMediaProducer({ assignedId }) {
    this.socket.emit("destroyMedia", {
      assignedId
    })
    const mediaProducer = this.mediaProducers.find(x => x.assignedId === assignedId)
    // if (mediaProducer.isDesktop) {
    //   this.normalizeOutgoingVideos();
    // }
    this.mediaProducers = this.mediaProducers.filter(x => x.assignedId !== assignedId);
    this.consolidateOutgoingVideoResolutions()
    if (this.videoElProps[assignedId]) {
      this.videoElProps[assignedId].videoEl.srcObject.getTracks().map(t => t.stop());
      delete this.videoElProps[assignedId]
      const self = this;
      Object.keys(this.videoElProps).map(x => {
        self.mainVideoEl = this.videoElProps[x].videoEl;
      })
    }

    this.callback({
      type: "destroyMedia",
      data: {
        assignedId
      }
    })
  }
  getStreamCount() {
    let ougoingVideoStreamCount = this.mediaProducers.filter(x => x.producer.kind === "video").length
    let incomingVideoStreamCount = this.mediaConsumers.filter(x => x.consumer.kind === "video").length
    const count = (ougoingVideoStreamCount + incomingVideoStreamCount)
    return count;
  }
  async consolidateOutgoingVideoResolutions() {
    let count = this.getStreamCount();
    if (this.mediaConsumers.find(x => x.isDesktop) || this.mediaProducers.find(x => x.isDesktop)) {
      // a desktop video is being shared so dont change resolutions
      return;
    }
    if (peerToWidthMapping[count]) {
      this.downgradeOutgoingVideos(peerToWidthMapping[count])
    } else {
      this.downgradeOutgoingVideos(null, 1)
    }
  }
  downgradeOutgoingVideos(desiredWidth = 300, desiredRatio) {
    Object.keys(this.videoElProps).map(assignedId => {
      let { type } = this.videoElProps[assignedId];
      if (type === "desktop") {
        // dont downgrade streaming screen video
        return;
      }
      this.downgradeOutgoingVideo(assignedId, desiredWidth, desiredRatio)
    })
  }
  downgradeOutgoingVideo(assignedId, desiredWidth = 300, ratio) {
    if (!this._adaptiveEnabled()) {
      return;
    }
    let { constraints } = this.videoElProps[assignedId];
    ratio = ratio || (constraints?.video?.width || 640) / desiredWidth;
    let count = this.getStreamCount();
    this.updateRtpParams(assignedId, {
      scaleResolutionDownBy: Math.max(ratio, 1),
      maxBitrate: 1000 * 1000 * (peerToBitrateMapping[count] || 1)
    })
  }
  normalizeOutgoingVideos() {
    Object.keys(this.videoElProps).map(assignedId => {
      let count = this.getStreamCount();
      this.updateRtpParams(assignedId, {
        scaleResolutionDownBy: 1,
        maxBitrate: 1000 * 1000 * (peerToBitrateMapping[count] || 1)
      })
    })
  }
  updateRtpParams(assignedId, params) {
    if (this.webrtcType === "p2p") {
      this.p2pAdapter.updateRtpParams(assignedId, params);
    } else {
      this.mediaProducers.map(mp => {
        if (mp.assignedId === assignedId) {
          mp.producer.setRtpEncodingParameters(params);
        }
        return ""
      })
    }
  }
  replaceTrack({ assignedId, track, constraints, stream, isAudio }) {
    if (this.mainVideoEl) {
      if (!isAudio)
        this.mainVideoEl.srcObject.getTracks().forEach(t => t.stop());
      if (this.webrtcType === "p2p") {
        if (!isAudio)
          this.mainVideoEl.srcObject.addTrack(track);// so that this track gets stopped next time the user changes their device
        this.p2pAdapter.replaceTrack({ assignedId, track, constraints, stream })
      } else {
        this.mediaProducers.map(mp => {
          if (mp.assignedId === assignedId) {
            mp.producer.replaceTrack({ track });
          }
          return ""
        })
      }
    }
    if (!isAudio && this.videoElProps[assignedId] && constraints) {
      this.videoElProps[assignedId].constraints = {
        video: {
          ...this.videoElProps[assignedId].constraints.video,
          ...constraints
        }
      }
      this.videoElProps[assignedId].videoEl = { srcObject: stream }
    }
    this.callback({
      type: "replacedLocalTrack",
      data: {
        assignedId,
        track,
        stream
      }
    })
  }
  changeAspectRatio({ assignedId, ratio }) {
    if (this.videoElProps[assignedId] && this.videoElProps[assignedId].constraints) {
      this.videoElProps[assignedId].constraints = {
        video: {
          ...this.videoElProps[assignedId].constraints.video,
          ...ASPECT_RATIO[this.videoElProps[assignedId].resolution],
          advanced: [
            { aspectRatio: { exact: ratio } },
          ]
        }
      }
      this.videoElProps[assignedId].ratio = ratio
    }
    this.changeInputVideoProps(assignedId, this.videoElProps[assignedId].constraints);
  }
  changeResolution({ assignedId, resolution }) {
    if (this.videoElProps[assignedId] && this.videoElProps[assignedId].constraints) {
      this.videoElProps[assignedId].constraints = {
        video: {
          ...this.videoElProps[assignedId].constraints.video,
          ...ASPECT_RATIO[resolution],
          advanced: [{ aspectRatio: { exact: 1.667 } }]
        }
      }
      this.videoElProps[assignedId].resolution = resolution
    }
    this.changeInputVideoProps(assignedId, this.videoElProps[assignedId].constraints);
    this.callback({
      type: "updateLocalStream",
      data: {
        resolution,
        assignedId
      }
    })
  }
  changeInputVideoProps(assignedId, constraints) {
    const streamOld = this.videoElProps[assignedId].videoEl.srcObject
    streamOld.getTracks()[0].applyConstraints(constraints.video)
      .then(() => {
        this.videoElProps[assignedId].srcObject = streamOld;
      })
  }
  pauseMediaProducer({ assignedId }) {
    this.mediaProducers.map(mp => {
      if (mp.assignedId === assignedId) {
        mp.producer.pause();
      }
      return ""
    })
  }
  resumeMediaProducer({ assignedId }) {
    this.mediaProducers.map(mp => {
      if (mp.assignedId === assignedId) {
        mp.producer.resume();
      }
      return "";
    })
  }
  createAudioProducer(constraints) {
    this.audioTransmitting = true;
    navigator.mediaDevices.getUserMedia(constraints)
      .then(async stream => {
        const assignedId = uuid();
        const producer = await this.producerTransport.produce({ track: stream.getAudioTracks()[0], appData: { assignedId } });
        this.mediaProducers.push({
          assignedId,
          producer
        });
        this.callback({
          type: "audiocreated",
          data: {
            stream,
            assignedId
          }
        })
      })
      .catch(e => {
        this.callback({
          type: "audioDevicePermissionIssue"
        })
      });
  }

  whiteNoise() {
    let canvas = Object.assign(document.createElement("canvas"), { width: 320, height: 240 });
    let ctx = canvas.getContext('2d');
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    let p = ctx.getImageData(0, 0, canvas.width, canvas.height);
    requestAnimationFrame(function draw() {
      for (var i = 0; i < p.data.length; i++) {
        p.data[i++] = p.data[i++] = p.data[i++] = Math.random() * 255;
      }
      ctx.putImageData(p, 0, 0);
      requestAnimationFrame(draw);
    });
    return canvas.captureStream(60);
  }
  toggleBW(assignedId) {
    if (!this.filters[assignedId]) {
      this.filters[assignedId] = {};
    }
    this.filters[assignedId].grayscale = !this.filters[assignedId].grayscale;
    this.callback({
      type: "updateFilter",
      data: {
        ...this.filters[assignedId],
        assignedId
      }
    })
    this.emit({
      type: "updateFilter",
      grayscale: this.filters[assignedId].grayscale,
      assignedId
    })
  }
  toggleBrightness(assignedId, val) {
    if (!this.filters[assignedId]) {
      this.filters[assignedId] = {};
    }
    let brightness = this.filters[assignedId].brightness || 1;
    if (!brightness) {
      brightness = 1;
    }
    brightness += val;

    if (brightness < 1) {
      brightness = 1;
    }
    if (brightness > 4) {
      brightness = 4;
    }
    this.filters[assignedId].brightness = brightness;
    this.callback({
      type: "updateFilter",
      data: {
        ...this.filters[assignedId],
        assignedId
      }
    })
    this.emit({
      type: "updateFilter",
      brightness: this.filters[assignedId].brightness,
      assignedId
    })
  }

  createCanvas(stream, assignedId) {
    const canvas = document.createElement("canvas");
    const videoEl = document.createElement("video");
    // this.mainVideoEl = videoEl;
    videoEl.srcObject = stream;
    videoEl.muted = true;
    // const self = this;
    // this.videoElProps[assignedId].videoEl = videoEl;
    videoEl.addEventListener("resize", () => {
      canvas.setAttribute("width", videoEl.videoWidth);
      canvas.setAttribute("height", videoEl.videoWidth / 1.667);
    })
    document.body.appendChild(videoEl);
    videoEl.setAttribute("style", "display: none");
    videoEl.oncanplay = () => {
      videoEl.play();
      if (!canvas || !this.videoElProps[assignedId]) {
        return;
      }
      canvas.setAttribute("height", videoEl.videoWidth / 1.667);
      canvas.setAttribute("width", videoEl.videoWidth);
      this.videoElProps[assignedId].canvas = canvas;
      const ctx = canvas.getContext("2d");
      const self = this;
      (function loop() {
        ctx.clearRect(0, 0, videoEl.videoWidth, videoEl.videoHeight);
        ctx.drawImage(videoEl, 0, 0, videoEl.videoWidth, videoEl.videoHeight); // draw the video
        // if (self.filters[assignedId] === BLACK_AND_WHITE) {
        //   ctx.fillStyle = "#888"; // gray colour
        //   ctx.globalAlpha = 1;   // amount of FX
        //   ctx.globalCompositeOperation = "color";  // The comp setting to do BLACK/WHITE
        //   ctx.fillRect(0, 0, videoEl.videoWidth, videoEl.videoHeight);  // Draw gray over the video
        //   ctx.globalAlpha = 1;  // reset alpha
        //   ctx.globalCompositeOperation = "source-over";  // reset comp
        // }
        // if (self.brightness[assignedId]) {
        //   ctx.filter = `brightness(${100 + (self.brightness[assignedId] - 2) * 25}%)`;
        // }
        setTimeout(loop, 1000 / 30); // drawing at 30fps
      })();
    }
    // // document.body.appendChild(videoEl);

    // // document.body.appendChild(canvas)
    return canvas.captureStream(24);
  }

  async createVideoProducer(constraints, stream, resolution, label) {
    resolution = resolution || 1;
    let videoConstraints = {
      ...constraints.video,
      ...ASPECT_RATIO[resolution],
      advanced: [
        { aspectRatio: { exact: 1.6667 } },
      ]
    };
    constraints = {
      ...constraints,
      video: videoConstraints
    }

    if (!stream) {
      let errThrown = false;
      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints)
      } catch (e) {
        errThrown = true;
        this.callback({
          type: "videoDevicePermissionIssue"
        })
      }
      if (errThrown) {
        return;
      }
    }

    if (!stream) {
      console.error("Permission denied");
      return;
    }

    let assignedId = uuid();
    if (this.webrtcType === "p2p") {
      assignedId = stream.id;
    }
    this.videoElProps[assignedId] = {
      type: "webcam",
      constraints: constraints,
      resolution: resolution,
      label,
      aspectRatio: 1.5,
      videoEl: {
        srcObject: stream
      }
    };
    this.mainVideoEl = {
      srcObject: stream
    };
    // stream = this.createCanvas(stream, assignedId);
    if (this.webrtcType === "p2p") {
      this.p2pAdapter.addVideoStream(stream);
    } else {
      let options = {
        stopTracks: false,
        track: stream.getVideoTracks()[0],
        appData: { assignedId, label }
      }
      if (this.simulcast) {
        options.encodings = WEBCAM_SIMULCAST_ENCODINGS;
      }
      const producer = await this.producerTransport.produce(options);
      this.mediaProducers.push({
        assignedId,
        producer,
      });
      this.consolidateOutgoingVideoResolutions()
      if (this.mediaConsumers.find(x => x.isDesktop)) {
        // a user is sharing desktop
        this.downgradeOutgoingVideo(assignedId)
      }
    }

    this.callback({
      type: "videocreated",
      data: {
        stream,
        assignedId,
        metadata: this.metadata,
        deviceId: constraints.video?.deviceId?.exact || constraints.video?.deviceId || null,
        resolution: resolution,
        label
      }
    })
  }
  getDisplayStream(constraints) {
    return navigator.mediaDevices.getDisplayMedia(constraints);
  }
  async createDesktopProducer(constraints, stream) {
    if (!stream) {
      stream = await this.getDisplayStream(constraints);
    }
    if (!stream) {
      console.error("Access denied while asking for screenshare");
      return;
    }
    let assignedId = uuid();
    if (this.webrtcType === "p2p") {
      assignedId = stream.id
      this.p2pAdapter.addVideoStream(stream, true);
    } else {
      const producer = await this.producerTransport.produce({
        stopTracks: false,
        track: stream.getVideoTracks()[0],
        appData: { assignedId, isDesktop: true }
      })
      this.mediaProducers.push({
        assignedId,
        producer,
        isDesktop: true
      });
    }
    this.videoElProps[assignedId] = {
      type: "desktop",
      constraints: constraints,
      videoEl: {
        srcObject: stream
      }
    };
    this.downgradeOutgoingVideos();
    this.callback({
      type: "desktopvideocreated",
      data: {
        stream,
        assignedId,
        isDesktop: true
      }
    })
    const self = this;
    stream.getVideoTracks()[0].onended = function () {
      self.destroyMediaProducer({ assignedId })
      self.callback({
        type: "desktopVideoDestroyed"
      })
    };
    ///conn.send(succes)
  }
  async destroyMedia(data) {
    const { assignedId } = data;
    // let mediaConsumer = this.mediaConsumers.find(x => x.assignedId === assignedId);
    // if (mediaConsumer?.isDesktop) {
    //   // this.normalizeOutgoingVideos();
    //   this.consolidateOutgoingVideoResolutions();
    // }
    this.mediaConsumers = this.mediaConsumers.filter(x => x.assignedId !== assignedId);
    this.consolidateOutgoingVideoResolutions();
    this.callback({
      type: "destroyMedia",
      data: {
        assignedId
      }
    })
  }
  async startRecording({ audioTrack, videoTrack }) {
    const assignedId = uuid();
    const videoProducer = await this.producerTransport.produce({
      track: videoTrack,
      appData: { assignedId, isRecording: true }
    })
    const audioProducer = await this.producerTransport.produce({
      track: audioTrack,
      appData: { assignedId, isRecording: true }
    })
  }
  async consumeMedia(data) {
    for (let i = 0; i < data.length; i++) {
      const { peerId, assignedId, consumerOptions, mediaId, isDesktop, audioMuted, metadata, name, user_id, filters, label } = data[i];
      if (!this.consumerTransport) {
        console.error("Consumer transport not yet initialized")
        return;
      }
      await this.consumerTransport.consume(consumerOptions)
        .then((newConsumer) => {
          this.mediaConsumers.push({
            assignedId,
            consumer: newConsumer,
            peerId,
            isDesktop
          })
          this.consolidateOutgoingVideoResolutions()
          if (isDesktop) {
            this.downgradeOutgoingVideos();
          }
          this.callback({
            type: "consumemedia", data: {
              track: newConsumer.track, peerId, assignedId, mediaId, isDesktop, audioMuted, metadata, name, user_id, filters, label
            }
          })
        })
    };
  }
  async getOutgoingBandwidth() {
    if (!this.producerTransport) {
      return {};
    }
    const stats = await this.producerTransport.getStats();
    let audioBytes = 0;
    let videoBytes = 0;
    stats.forEach(r => {
      if (r.type.includes("outbound-rtp")) {
        if (r.kind === "audio") {
          audioBytes += r.bytesSent;
        } else {
          videoBytes += r.bytesSent;
        }
      }
    })
    return {
      videoBytes,
      audioBytes
    }
  }
  async getIncomingBandwidth() {
    if (!this.consumerTransport) {
      return {};
    }
    const stats = await this.consumerTransport.getStats();
    let audioBytes = 0;
    let videoBytes = 0;
    stats.forEach(r => {
      if (r.type.includes("inbound-rtp")) {
        if (r.kind === "audio") {
          audioBytes += r.bytesReceived;
        } else {
          videoBytes += r.bytesReceived;
        }
      }
    })
    return {
      videoBytes,
      audioBytes
    }
  }
}
