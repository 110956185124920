import { Grid } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState, useContext } from "react";
import { DeviceContext } from "../DeviceContext";
import config from "../config";
import {
  BROADCASTER_MODE,
  JAMMER_MODE,
  PERFORMER_AND_BROADCASTER,
  PERFORMER_MODE,
  PERFORMER_WITH_BROADCASTER,
} from "../constants";
import { AdminControls } from "./AdminControls";
import communicationAdapter from "./communicationAdapter";
import { FabricCanvasRenderer } from "./FabricCanvasRenderer";
import { Layout } from "./layoutv2/Layout";
import { ModeButtons } from "./ModeButtons";
import PermissionModal from "./PermissionModal";
import { QWebChannel } from "./qwebchannel";
import { RecordCloud } from "./RecordCloud";
import recordingAdapter from "./recordingAdapter";
import { Videos } from "./Videos";
import { NAME_AND_LOCATION } from "./constants";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let mouseInterval;

function JamkazamVideo({
  room,
  admin,
  audiooff,
  token,
  canvas,
  setRestart,
  debug,
  producerState,
  setForcedSfu,
  setProducerState,
  setInitialVideoProps,
  initialVideoProps,
  isP2p = true,
  simulcast,
  session_creator,
}) {
  const [layout, setLayout] = useState(
    parseInt(localStorage.getItem("layout-jk") || 0)
  );
  //const [videoDevices, setVideoDevices] = useState([]);
  const [videoOn, setVideoOn] = useState(true);
  const [screenShareOn, setScreenShareOn] = useState(false);
  const [localVideoStreams, setLocalVideoStreams] = useState([]);
  const [peers, setPeers] = useState([]);
  const [localAudioStreams, setLocalAudioStreams] = useState([]);
  const [audioPermissionError, setAudioPermissionError] = useState(false);
  const [videoPermissionError, setVideoPermissionError] = useState(false);
  const [audioState, setAudioState] = useState({
    local: { audioMuted: audiooff },
  });
  const [pinned, setPinned] = useState(null);
  const [solo, setSolo] = useState(null);
  const [videoAssignedId, setVideoAssignedId] = useState(null);
  const [audioAssignedId, setAudioAssignedId] = useState(null);
  const [someoneIsSharing, setSomeoneIsSharing] = useState({
    peerId: null,
    assignedId: null,
  });
  const [mouseNotMoved, setMouseNotMoved] = useState(true);
  const [name, setName] = useState("");
  const [videoEls, setVideoEls] = useState([]);
  const [videoStreams, setVideoStreams] = useState([]);
  const [audioStreams, setAudioStreams] = useState([]);
  const [mode, setMode] = useState(JAMMER_MODE);
  const [firstMode, setFirstMode] = useState(false);
  const [unAuthorized, setUnauthorized] = useState(false);
  const [broadcaster, setBroadcaster] = useState(null);
  const [rightRotationHack, setRightRotationHack] = useState(false);
  const [leftRotationHack, setLeftRotationHack] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [flipOutgoingOrientation, setFlipOutgoingOrientation] = useState(false);
  const [labelMode, setLabelMode] = useState(NAME_AND_LOCATION);

  const { userVideoDevices } = useContext(DeviceContext);

  const [couldNotConnectNativeApp, setCouldNotConnectNativeApp] =
    useState(false);

  //const [websocketConnected, setWebsocketConnected] = useState(false);

  useEffect(() => {
    if (videoStreams.length >= config.options.p2pThreshold) {
      if (window.adapter.webrtcType === "p2p") {
        window.adapter.p2pAdapter.sendSwitchToSfu();
        window.adapter.p2pAdapter.switchToSfu();
      }
    }
    consolidateIncomingVideos();
  }, [videoStreams]);

  const consolidateIncomingVideos = () => {
    if (someoneIsSharing.peerId) {
      screenshareSimulcast(someoneIsSharing.peerId);
      return;
    }
    if (solo) {
      soloSimulcast(solo);
      return;
    }
    let mapping = {};
    videoStreams.map(({ assignedId }) => {
      mapping[assignedId] = videoStreams.length >= 3 ? 1 : 2;
    });
    if (layout === 2) {
      mapping[videoStreams[0]?.assignedId] = 2;
    }
    if (layout === 1 && Object.keys(videoStreams).length >= 4) {
      videoStreams.map(({ assignedId }) => {
        mapping[assignedId] = 0;
      });
    }
    if (window.adapter && window.adapter.consolidateIncomingVideos) {
      window.adapter.consolidateIncomingVideos(mapping);
    }
  };

  const flipLocal = ({ assignedId, direction }) => {
    setVideoStreams((streams) =>
      streams.map((vs) => {
        if (vs.assignedId !== assignedId) {
          return vs;
        }
        return {
          ...vs,
          [`${direction}Flip`]: !vs[`${direction}Flip`],
        };
      })
    );
    setLocalVideoStreams((streams) =>
      streams.map((vs) => {
        if (vs.assignedId !== assignedId) {
          return vs;
        }
        return {
          ...vs,
          [`${direction}Flip`]: !vs[`${direction}Flip`],
        };
      })
    );
  };
  useEffect(() => {
    consolidateIncomingVideos();
    localStorage.setItem("layout-jk", layout);
  }, [layout]);

  useEffect(() => {
    if (someoneIsSharing.peerId) screenshareSimulcast(someoneIsSharing.peerId);
    else consolidateIncomingVideos();
  }, [someoneIsSharing.peerId]);

  useEffect(() => {
    if (solo) soloSimulcast(solo);
    else consolidateIncomingVideos();
  }, [solo]);

  const screenshareSimulcast = (peerId) => {
    if (peerId) {
      let mapping = {};
      videoStreams.map(({ assignedId }) => {
        mapping[assignedId] = 0;
      });
      window.adapter.consolidateIncomingVideos(mapping);
    } else {
      consolidateIncomingVideos();
    }
  };

  const soloSimulcast = (solo) => {
    let mapping = {};
    videoStreams.map(({ assignedId }) => {
      mapping[assignedId] = videoStreams.length > 3 ? 1 : 2;
    });
    mapping[solo] = 2;
    window.adapter.consolidateIncomingVideos(mapping);
  };

  useEffect(() => {
    rotateLeft();
  }, [leftRotationHack]);

  useEffect(() => {
    rotateRight();
  }, [rightRotationHack]);

  useEffect(() => {
    window.videoStreams = videoStreams;
  }, [videoStreams]);

  useEffect(() => {
    let localVideos = [];
    videoStreams.map((vs) => {
      if (vs.peerId === "local") {
        localVideos.push(vs);
      }
    });
    setProducerState((p) => ({
      ...p,
      localVideos,
    }));
  }, [videoStreams]);

  useEffect(() => {
    setProducerState((p) => ({
      ...p,
      localAudios: localAudioStreams,
    }));
  }, [localAudioStreams]);

  const stopAllOutgoingVideos = () => {
    localVideoStreams.map((vs) => {
      window.adapter.destroyMediaProducer({
        assignedId: vs.assignedId,
      });
      vs.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    });
    setLocalVideoStreams([]);
    setVideoStreams((streams) => streams.filter((vs) => vs.peerId !== "local"));
  };

  const receiveAllIncomingVideos = () => {
    window.adapter.resumeAllConsumers();
  };

  const pauseAllIncomingVideos = () => {
    window.adapter.pauseAllConsumers();
  };

  const startAtleastOneVideoIfEmpty = () => {
    let camStarted = false;
    if (userVideoDevices.length > 0) {
      setLocalVideoStreams((localVideoStreams) => {
        if (localVideoStreams.length === 0 && !camStarted) {
          camStarted = true;
          window.adapter.createVideoProducer(
            { video: { deviceId: { exact: userVideoDevices[0].deviceId } } },
            null,
            null,
            userVideoDevices[0].label
          );
        }
        return localVideoStreams;
      });
    }
  };

  useEffect(() => {
    if (!firstMode) {
      // dont do anything if the first time the mode is being set
      // because we already set based on the PERFORMER_AND_BROADCASTER mode
      setFirstMode(true);
      return;
    }
    if (mode === JAMMER_MODE) {
      receiveAllIncomingVideos();
      startAtleastOneVideoIfEmpty();
    }
    if (mode === PERFORMER_MODE) {
      pauseAllIncomingVideos();
      startAtleastOneVideoIfEmpty();
    }
    if (mode === BROADCASTER_MODE) {
      stopAllOutgoingVideos();
      receiveAllIncomingVideos();
    }
    if (mode === PERFORMER_AND_BROADCASTER) {
      receiveAllIncomingVideos();
      startAtleastOneVideoIfEmpty();
    }
    if (mode === PERFORMER_WITH_BROADCASTER) {
      startAtleastOneVideoIfEmpty();
      receiveAllIncomingVideos();
    }
  }, [mode]);

  const toggleScreenshare = () => {
    setScreenShareOn((screenShareOn) => {
      if (!screenShareOn) {
        window.adapter.createDesktopProducer({
          video: {
            width: { max: 1200 },
            height: { max: 720 },
          },
        });
        return screenShareOn;
      } else {
        localVideoStreams.map((vs) => {
          if (vs.type === "desktop") {
            window.adapter.destroyMediaProducer({
              assignedId: vs.assignedId,
            });
            vs.stream.getTracks().forEach(function (track) {
              track.stop();
            });
          }
          return "";
        });
        setLocalVideoStreams((vs) => vs.filter((s) => s.type !== "desktop"));
        sendControlUpdateToNativeApp("screenshare", false);
        return false;
      }
    });
  };
  const startCam = () => {
    let cameraSet = false;
    userVideoDevices.map((device) => {
      if (cameraSet) {
        return;
      }
      Object.keys(window.adapter.videoElProps).map((x) => {
        const oldVideoConstraints =
          window.adapter.videoElProps[x]?.constraints?.video;
        if (!oldVideoConstraints) {
          return;
        }
        let oldVideoDeviceId =
          window.adapter.videoElProps[x]?.constraints?.video?.deviceId?.exact ||
          window.adapter.videoElProps[x]?.constraints?.video?.deviceId;
        if (device.deviceId !== oldVideoDeviceId) {
          window.adapter.createVideoProducer(
            { video: { deviceId: device.deviceId } },
            null,
            null,
            device.label
          );
          cameraSet = true;
        }
      });
    });
    if (!cameraSet) {
      window.adapter.createVideoProducer(
        { video: { deviceId: userVideoDevices[0].deviceId } },
        null,
        null,
        userVideoDevices[0].label
      );
    }
  };

  useEffect(() => {
    const conn = new WebSocket(config.localWsServerUrl);
    window.conn = conn;
    conn.onopen = () => {
      new QWebChannel(conn, function (channel) {
        const jklocalwebchannel = channel.objects.jklocalwebchannel;
        window.jklocalwebchannel = jklocalwebchannel;
        jklocalwebchannel.sendText.connect(function (message) {
          var inputMessage = message;
          processJkWebChannelMessage(inputMessage);
        });
      });

      //setWebsocketConnected(true); // set the websocket connected state so that we can show the UI
    };
    conn.onerror = (e) => {
      console.error(e);
    };
    mouseMoved();
  }, []);

  const mouseMoved = () => {
    if (mouseInterval) {
      window.clearTimeout(mouseInterval);
    }
    if (window.adapter && !window.adapter.broadcasting) {
      setMouseNotMoved(true);
    }
    mouseInterval = window.setTimeout(() => {
      setMouseNotMoved(false);
    }, 5000);
  };

  const toggleMute = (peerId, newAudioMuted) => {
    setLocalAudioStreams((localAudioStreams) => {
      if (peerId === "local") {
        if (!localAudioStreams[0]) {
          if (window.adapter.audioTransmitting) {
            console.warn("Creating audio element!");
            return localAudioStreams;
          }
          if (!newAudioMuted) {
            window.adapter.createAudioProducer({ audio: false });
            window.adapter.muteToggled(false);
            toggleMuteIcon(peerId, newAudioMuted);
          } else {
            console.log(
              "local audio stream not found, have you provided access?"
            );
          }
          return localAudioStreams;
        }
        if (newAudioMuted) {
          window.adapter.pauseMediaProducer({
            assignedId: localAudioStreams[0].assignedId,
          });
          window.adapter.muteToggled(newAudioMuted);
        } else {
          window.adapter.resumeMediaProducer({
            assignedId: localAudioStreams[0].assignedId,
          });
          window.adapter.muteToggled(newAudioMuted);
        }
        toggleMuteIcon(peerId, newAudioMuted);
      } else {
        // user is a remote peer
        window.adapter.muteUser(peerId);
      }
      return localAudioStreams;
    });
  };

  const toggleMuteIcon = (peerId, newAudioMuted) => {
    setAudioState((audioState) => ({
      ...audioState,
      [peerId]: {
        ...audioState[peerId],
        audioMuted: newAudioMuted,
      },
    }));
  };

  useEffect(() => {
    // if(!websocketConnected){
    //   return;
    // }

    const obj = { peers: [] };

    videoStreams.map((vs) => {
      const data = {
        name: vs.name,
        assignedId: vs.assignedId,
      };
      obj.peers.push(data);
    });
    obj.userVideoDevices = userVideoDevices.map((x) => ({ label: x.label }));
    sendControlUpdateToNativeApp("streams", obj);
  }, [videoStreams]);

  const sendState = () => {};

  const muteMe = () => {
    toggleMute("local", true);
  };

  const sendControlUpdateToNativeApp = (controlType, controlState) => {
    //use an interval to check if window.jklocalwebchannel is available. wait for 5 seconds
    let maxTries = 5;
    const waitInterval = setInterval(() => {
      if (window.jklocalwebchannel) {
        clearInterval(waitInterval);
        try {
          var typeObj = new Object();
          typeObj.type = controlType;
          typeObj.value = controlState;
          var videoControlUpdateMessage = new Object();
          videoControlUpdateMessage.data = typeObj;
          window.jklocalwebchannel.receiveText(
            JSON.stringify(videoControlUpdateMessage)
          );
          console.log("Sent control update to native app", JSON.stringify(videoControlUpdateMessage));
        } catch (e) {
          if (!couldNotConnectNativeApp) {
            console.error("Native app not connected", e);
            setCouldNotConnectNativeApp(true);
          }
        }
      }else{
        maxTries--;
        if(maxTries <= 0){
          clearInterval(waitInterval);
        }
      }
    }, 1000);
  };
  // try {
  //   var typeObj = new Object();
  //   typeObj.type = controlType;
  //   typeObj.value = controlState
  //   var videoControlUpdateMessage = new Object();
  //   videoControlUpdateMessage.data = typeObj;
  //   //if(window.jklocalwebchannel)
  //   window.jklocalwebchannel.receiveText(JSON.stringify(videoControlUpdateMessage));
  // } catch (e) {
  //   if (!couldNotConnectNativeApp) {
  //     console.error("Native app not connected", e);
  //     setCouldNotConnectNativeApp(true);
  //   }
  // }
  //}

  const processJkWebChannelMessage = (message) => {
    const msg = JSON.parse(message);
    if (msg.data.type === "muted") {
      toggleMute("local", msg.data.value);
    } else if (msg.data.type === "camera") {
      if (msg.data.value) {
        startCam();
      }
    } else if (msg.data.type === "screenshare") {
      toggleScreenshare();
    } else if (msg.data.type === "360p") {
      window.adapter.changeResolution({ videoAssignedId, resolution: 0 });
    } else if (msg.data.type === "720p") {
      setVideoAssignedId((videoAssignedId) => {
        window.adapter.changeResolution({ videoAssignedId, resolution: 1 });
        return videoAssignedId;
      });
    } else if (msg.data.type === "1080p") {
      window.adapter.changeResolution({ videoAssignedId, resolution: 2 });
    } else if (msg.data.type === "fliphorizontal") {
      // changeHorizontalOrientation(!horizontalFlip, videoAssignedId);
    } else if (msg.data.type === "flipvertical") {
      // changeVerticalOrientation(!verticalFlip, videoAssignedId);
    } else if (msg.data.type === "blackandwhite") {
      window.adapter.toggleBW(videoAssignedId);
    } else if (msg.data.type === "solo") {
      setSolo(msg.data.value);
    } else if (msg.data.type === "broadcastrole") {
      if (msg.data.value == 1) {
        console.error("Setting role to Jammer");
      } else if (msg.data.value == 2) {
        console.error("Setting role to Performer");
      } else if (msg.data.value == 3) {
        console.error("Setting role to Broadcaster");
      } else if (msg.data.value == 4) {
        console.error("Setting role to Performer And Broadcaster");
      } else if (msg.data.value == 5) {
        console.error("Setting role to Performer With Broadcaster");
      }
    } else if (msg.data.type === "layout") {
      if (msg.data.value == 1) {
        setLayout(1);
        console.error("Setting layout to Side By Side");
      } else if (msg.data.value == 2) {
        setLayout(0);
        console.error("Setting layout to Brady Bunch");
      } else if (msg.data.value == 3) {
        setLayout(2);
        console.error("Setting layout to Left featured, Right stacked");
      }
    } else if (msg.data.type === "pinuser") {
      console.error("User pinned: %s", msg.data.value);
      setPinned(msg.data.value);
    } else if (msg.data.type === "leftrotate") {
      console.error("Performing left rotate");
      // rotateLeft();
      // Perform left rotate
      setLeftRotationHack((x) => !x);
    } else if (msg.data.type === "rightrotate") {
      console.error("Performing right rotate");
      // rotateRight()
      setRightRotationHack((x) => !x);
      // Perform right rotate
    } else if (msg.data.type === "broadcast") {
      window.adapter.broadcasting = msg.data.value;
      if (msg.data.value) {
        // Disable UI buttons
        console.error("Broadcast mode set");
      } else {
        // Enable UI buttons
        console.error("Broadcast mode cleared");
      }
    } else if (msg.data.type === "closewebrtcstreams") {
      stopAllOutgoingVideos();
    }
  };

  const changeLayout = async (val) => {
    await setLayout((layout) => (val || val === 0 ? val : (layout + 1) % 3));
    await setPinned(false);
    await setSolo(null);
  };
  const rotateRight = () => {
    let itsDone = false;
    setPinned((pinned) => {
      if (itsDone) {
        return pinned;
      }
      itsDone = true;
      setVideoStreams((videoStreams) => {
        if (videoStreams.length === 0 || videoStreams.length === 1) {
          return videoStreams;
        }
        let videoStreamsTemp = [...videoStreams];
        let pinnedVideoIndex = videoStreamsTemp.findIndex(
          (x) => x.assignedId === pinned
        );
        if (pinnedVideoIndex === -1) {
          let lastElement = videoStreamsTemp[videoStreamsTemp.length - 1];
          videoStreamsTemp.splice(videoStreamsTemp.length - 1, 1);
          videoStreamsTemp.splice(0, 0, lastElement);
          return videoStreamsTemp;
        } else {
          let pinnedVideo = videoStreamsTemp[pinnedVideoIndex];
          videoStreamsTemp.splice(pinnedVideoIndex, 1);
          let lastElement = videoStreamsTemp[videoStreamsTemp.length - 1];
          videoStreamsTemp.splice(videoStreamsTemp.length - 1, 1);
          videoStreamsTemp.splice(0, 0, lastElement);
          videoStreamsTemp.splice(pinnedVideoIndex, 0, pinnedVideo);
          return videoStreamsTemp;
        }
      });
      return pinned;
    });
  };

  const rotateLeft = () => {
    let itsDone = false;
    setPinned((pinned) => {
      if (itsDone) {
        return pinned;
      }
      itsDone = true;
      setVideoStreams((videoStreams) => {
        if (videoStreams.length === 0 || videoStreams.length === 1) {
          return videoStreams;
        }
        let videoStreamsTemp = [...videoStreams];
        let pinnedVideoIndex = videoStreamsTemp.findIndex(
          (x) => x.assignedId === pinned
        );
        if (pinnedVideoIndex === -1) {
          console.error(videoStreamsTemp);
          let firstElement = videoStreamsTemp[0];
          videoStreamsTemp.splice(0, 1);
          videoStreamsTemp.push(firstElement);
          console.error(videoStreamsTemp);
          return videoStreamsTemp;
        } else {
          let pinnedVideo = videoStreamsTemp[pinnedVideoIndex];
          videoStreamsTemp.splice(pinnedVideoIndex, 1);
          let firstElement = videoStreamsTemp[0];
          videoStreamsTemp.splice(0, 1);
          videoStreamsTemp.push(firstElement);
          videoStreamsTemp.splice(pinnedVideoIndex, 0, pinnedVideo);
          return videoStreamsTemp;
        }
      });
      return pinned;
    });
  };

  const dataChannelHandler = ({ type, data }) => {
    if (type === "activeUser") {
      setVideoStreams((streams) =>
        streams.map((vs) => {
          console.error(data.peerId);
          console.error(vs.peerId);
          console.error(vs.peerId === "local" ? window.adapter.id : vs.peerId);
          console.error(
            (vs.peerId === "local" ? window.adapter.id : vs.peerId) !==
              data.peerId
          );
          if (
            (vs.peerId === "local" ? window.adapter.id : vs.peerId) !==
            data.peerId
          ) {
            return {
              ...vs,
              isActive: false,
            };
          }

          return {
            ...vs,
            isActive: true,
          };
        })
      );
    }
  };

  const adapterCallback = ({ type, data }) => {
    //console.log("_DEBUG_ adapterCallback", type, data)
    if (type === "unauthorized") {
      //window.location.href = config.jkSigninUrl;
      setUnauthorized(true);
    }
    if (type === "audioDevicePermissionIssue") {
      setAudioPermissionError(true);
    }
    if (type === "videoDevicePermissionIssue") {
      setVideoPermissionError(true);
    }
    if (type === "updateLocalStream") {
      setLocalVideoStreams((vs) =>
        vs.map((streamProps) => {
          if (streamProps.assignedId === data.assignedId) {
            return {
              ...streamProps,
              ...data,
            };
          }
          return streamProps;
        })
      );
    }
    if (type === "botmessage") {
      try {
        dataChannelHandler(JSON.parse(data));
      } catch (e) {}
    }
    if (type === "changeRecordingLayout") {
      setVideoStreams((vs) => [
        ...vs.sort((a, b) =>
          data.props.videoStreamIds.findIndex((x) => x === a.assignedId) <
          data.props.videoStreamIds.findIndex((x) => x === b.assignedId)
            ? -1
            : 1
        ),
      ]);
    }
    if (type === "isRecording") {
      setIsRecording(data.value);
    }
    if (type === "forceSfu") {
      setForcedSfu(true);
      setRestart(true);
    }

    if (type === "producerScore") {
      setVideoStreams((vs) =>
        vs.map((s) => {
          if (s.assignedId === data.assignedId) {
            return {
              ...s,
              score: data.score,
            };
          }
          return s;
        })
      );
    }

    if (type === "addedDesktopProducer") {
      if (window.adapter.webrtcType === "p2p") {
        window.adapter.p2pAdapter.desktopMediaIds.push(data.mediaId);
      }
    }
    if (type === "transportsCreated" || type === "p2pInitialized") {
      initialVideoProps.forEach(({ constraints, stream, type, label }) => {
        if (type !== "desktop")
          window.adapter.createVideoProducer(constraints, stream, null, label);
        else window.adapter.createDesktopProducer(constraints, stream);
      });
    }
    if (type === "signalDisconnected") {
      setInitialVideoProps(data.videos);
      setRestart(true);
    }
    if (type === "peerAdded") {
      setPeers((p) => [
        ...p,
        {
          name: data.name,
          peerId: data.peerId,
        },
      ]);
    }
    if (type === "updateFilter") {
      setVideoStreams((streams) =>
        streams.map((vs) => {
          if (vs.assignedId !== data.assignedId) {
            return vs;
          }
          return {
            ...vs,
            filters: {
              ...vs.filters,
              ...data,
            },
          };
        })
      );
    }
    if (type === "identity") {
      setBroadcaster(data.broadcaster);
      setPeers(data.peers || []);
    } else if (type === "consumemedia") {
      const stream = new MediaStream();
      stream.addTrack(data.track);
      if (data.track.kind === "audio") {
        let audioelement = document.createElement("audio");
        audioelement.setAttribute("autoplay", true);
        audioelement.setAttribute("playsinline", true);
        audioelement.setAttribute("id", "audio" + data.assignedId);
        audioelement.setAttribute("peerid", data.peerId);
        audioelement.srcObject = stream;
        setAudioState((astate) => {
          window.setTimeout(() => {
            toggleMuteIcon(data.peerId, data.audioMuted);
          }, 50);
          return {
            ...astate,
            [data.peerId]: {
              audioMuted: data.audioMuted,
              assignedId: data.assignedId,
            },
          };
        });
        document.body.appendChild(audioelement);
        toggleMuteIcon(data.peerId, data.audioMuted);
        setAudioStreams((vs) => [
          ...vs,
          {
            assignedId: data.assignedId,
            stream: stream,
            peerId: data.peerId,
          },
        ]);
      } else {
        data.track.onmute = () => {
          setVideoStreams((streams) =>
            streams.map((vs) => {
              if (vs.assignedId !== data.assignedId) {
                return vs;
              }
              return {
                ...vs,
                isMuted: true,
              };
            })
          );
        };
        data.track.onunmute = () => {
          setVideoStreams((streams) =>
            streams.map((vs) => {
              if (vs.assignedId !== data.assignedId) {
                return vs;
              }
              return {
                ...vs,
                isMuted: false,
              };
            })
          );
        };
        setVideoStreams((vs) => [
          ...vs,
          {
            assignedId: data.assignedId,
            stream: stream,
            peerId: data.peerId,
            filter: "",
            horizontalFlip: false,
            verticalFlip: false,
            name: data.name,
            filters: data.filters || {},
            user_id: data.user_id,
            label: data.label,
          },
        ]);
        if (data.isDesktop) {
          setSomeoneIsSharing({
            assignedId: data.assignedId,
            peerId: data.peerId,
          });
        }
      }
    }
    if (type === "disconnect") {
      // const els = document.querySelectorAll(`.peerId-${data.peerId}`);
      // els.forEach(el => {
      //   if (el.parentNode) {
      //     el.parentNode.removeChild(el);
      //   }
      // })
      setAudioStreams((vs) => vs.filter((s) => s.peerId !== data.peerId));
      setPeers((x) => x.filter((y) => y.peerId !== data.peerId));
      setVideoStreams((vs) => {
        setPinned((pinned) => {
          if (
            vs
              .filter((s) => s.peerId !== data.peerId)
              .findIndex((x) => x.assignedId === pinned) === -1
          ) {
            return false;
          }
          return pinned;
        });
        setSolo((solo) => {
          if (
            vs
              .filter((s) => s.peerId !== data.peerId)
              .findIndex((x) => x.assignedId === solo) === -1
          ) {
            return false;
          }
          return solo;
        });

        return vs.filter((s) => s.peerId !== data.peerId);
      });
      setSomeoneIsSharing((s) => {
        if (s.peerId === data.peerId) {
          return {};
        }
        return s;
      });
    }
    if (type === "destroyMedia") {
      const el = document.querySelector(`.outerDiv-${data.assignedId}`);
      if (el && el.parentNode) {
        setVideoEls((vc) =>
          vc.filter((assignedId) => assignedId !== data.assignedId)
        );
        el.parentNode.removeChild(el);
      }
      setVideoStreams((vs) =>
        vs.filter((el) => el.assignedId !== data.assignedId)
      );
      setPinned((pinned) => {
        if (pinned === data.assignedId) {
          return false;
        }
        return pinned;
      });
      setSolo((solo) => {
        if (solo === data.assignedId) {
          return false;
        }
        return solo;
      });
      setSomeoneIsSharing((s) => {
        if (s.assignedId === data.assignedId) {
          return {};
        }
        return s;
      });
      sendState();
    }
    if (type === "videocreated") {
      const horizontalFlip =
        localStorage.getItem("jk-horizontal-flip") === "true" ? true : false;
      setVideoStreams((vs) => [
        ...vs,
        {
          assignedId: data.assignedId,
          stream: data.stream,
          peerId: "local",
          filter: "",
          horizontalFlip,
          verticalFlip: false,
          isLocal: true,
          name: "You",
          user_id: "local",
          label: data.label,
          filters: {},
        },
      ]);
      setVideoAssignedId(data.assignedId);
      setMode((mode) => {
        // This might get tricky with performer with broadcaster mode
        // if (mode === PERFORMER_MODE) {
        //   window.adapter.pauseMediaProducer({ assignedId: data.assignedId });
        // }
        return mode;
      });
      setLocalVideoStreams((vs) => [
        ...vs,
        {
          stream: data.stream,
          assignedId: data.assignedId,
          isLocal: true,
          type: "camera",
          deviceId: data.deviceId,
          resolution: data.resolution,
          label: data.label,
        },
      ]);
      sendControlUpdateToNativeApp("camera", true);
    }
    if (type === "audiocreated") {
      setAudioAssignedId(data.assignedId);
      setAudioState((astate) => ({
        ...astate,
        ["local"]: {
          audioMuted: false,
        },
      }));
      setLocalAudioStreams((vs) => [
        ...vs,
        {
          stream: data.stream,
          assignedId: data.assignedId,
          isLocal: true,
        },
      ]);
    }
    if (type === "desktopvideocreated") {
      sendControlUpdateToNativeApp("screenshare", true);
      setScreenShareOn(true);
      setLocalVideoStreams((vs) => [
        ...vs,
        {
          stream: data.stream,
          assignedId: data.assignedId,
          isLocal: true,
          type: "desktop",
        },
      ]);
    }
    if (type === "muteUser") {
      muteMe();
    }
    if (type === "toggleMute") {
      toggleMuteIcon(data.peerId, data.audioMuted);
    }
    if (type === "desktopVideoDestroyed") {
      setLocalVideoStreams((vs) => vs.filter((s) => s.type !== "desktop"));
      setScreenShareOn(false);
      sendState();
    }
    if (type === "setBroadcaster") {
      setBroadcaster(data.peerId);
      if (data.moveToJammer) {
        setMode(JAMMER_MODE);
      }
    }
    if (type === "replacedLocalTrack") {
      setVideoStreams((vs) =>
        vs.map((s) => {
          if (s.assignedId === data.assignedId) {
            return {
              ...s,
              stream: data.stream,
            };
          }
          return s;
        })
      );
    }
    if (type === "flip") {
      if (data.direction === "horizontal") {
        setVideoStreams((streams) =>
          streams.map((vs) => {
            console.error(streams);
            if (vs.assignedId !== data.assignedId) {
              return vs;
            }
            return {
              ...vs,
              horizontalFlip: !vs.horizontalFlip,
            };
          })
        );
      } else {
        setVideoStreams((streams) =>
          streams.map((vs) => {
            if (vs.assignedId !== data.assignedId) {
              return vs;
            }
            return {
              ...vs,
              verticalFlip: !vs.verticalFlip,
            };
          })
        );
      }
    }
    if (type === "setname") {
      setName(data.name);
    }
    if (type === "audiocreated") {
    }
  };

  useEffect(() => {
    window.adapter = new communicationAdapter(
      config.backendURL,
      room,
      { name, token },
      { audio: !audiooff && !canvas },
      adapterCallback,
      isP2p ? "p2p" : "sfu",
      simulcast,
      canvas
    );

    //Note: disable recording adapter for now
    // try {
    //   window.recordingAdapter = new recordingAdapter(config.recordingBackendUrl, room, { name, token }, { audio: !audiooff && !canvas }, adapterCallback, "sfu", { isRecorder: true, connectToWebrtc: canvas })
    // } catch (error) {
    //   console.error("Recording adapter not initialized")
    // }

    return () => {
      window.adapter.disconnect();
    };
  }, [room]);

  const getVideoStreamsBasedOnMode = () => {
    if (mode === BROADCASTER_MODE) {
      return videoStreams;
    }
    if (mode === JAMMER_MODE) {
      return videoStreams;
    }
    if (mode === PERFORMER_MODE) {
      return videoStreams.filter((s) => s.peerId === "local");
    }
    if (mode === PERFORMER_AND_BROADCASTER) {
      return videoStreams;
    }
    if (mode === PERFORMER_WITH_BROADCASTER) {
      return videoStreams.filter(
        (s) => s.peerId === "local" || s.peerId === broadcaster
      );
    }
    return videoStreams;
  };

  // if(!websocketConnected) {
  //   return (
  //     <div
  //       style={{
  //         color: "white",
  //         height: "100vh",
  //         display: "flex",
  //         flexDirection: "column",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Typography variant="h6"> Trying to connect </Typography>
  //       <center>
  //         <CircularProgress color="secondary" />{" "}
  //       </center>
  //     </div>
  //   );
  // }

  if (unAuthorized) {
    return (
      <Grid container>
        <Grid item sm={4}></Grid>
        <Grid item xs={12} sm={4}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <Alert severity="error">Invalid room credentials</Alert>
        </Grid>
      </Grid>
    );
  }

  if (userVideoDevices.length <= 0) {
    return (
      <Alert severity="warning">
        Connect at least one video capture device and reload this page.
      </Alert>
    );
  }

  // return (
  //   <div style={{"width": "100vw", height: "100vh"}}>
  //     <Layout />
  //   </div>
  // )
  return (
    <div onMouseMove={mouseMoved}>
      <div
        style={{
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          justifyItems: "center",
          flexDirection: "column",
        }}
      >
        {/* {canvas && debug && <div>
          <br/><br/>
          <center>
            <canvas width="1920" height="1080" style={{width: "1920", height: "1080"}} id="maincanvas">

            </canvas>
          </center>
        </div>} */}
        {/* {canvas && <CanvasRenderer
          videoStreams={videoStreams}
          debug={debug}
        />} */}
        {canvas && (
          <>
            {" "}
            <FabricCanvasRenderer videoStreams={videoStreams} debug={debug} />
            <RecordCloud
              localAudioStreams={localAudioStreams}
              audioStreams={audioStreams}
            />
          </>
        )}
        {!canvas && (
          <Videos
            flipLocal={flipLocal}
            labelMode={labelMode}
            localVideoStreams={localVideoStreams}
            flipOutgoingOrientation={flipOutgoingOrientation}
            someoneIsSharing={someoneIsSharing}
            simulcast={simulcast}
            setVideoStreams={setVideoStreams}
            mouseNotMoved={mouseNotMoved}
            pinned={pinned}
            solo={solo}
            layout={layout}
            videoStreams={getVideoStreamsBasedOnMode()}
            setLocalAudioStreams={setLocalAudioStreams}
            setVideoAssignedId={setVideoAssignedId}
            setLocalVideoStreams={setLocalVideoStreams}
            setPinned={setPinned}
            setSolo={setSolo}
            setAudioState={setAudioState}
            setControl
            audioState={audioState}
          />
        )}
      </div>
      <br />
      <div
        style={{
          height: "80px",
          visibility: mouseNotMoved ? "visible" : "hidden",
        }}
      >
        {/* <div style={{color: "white"}}>{videoAssignedId}</div> */}
        {/* <ControlsNew
          session_creator={session_creator}
          flipOutgoingOrientation={flipOutgoingOrientation}
          setFlipOutgoingOrientation={setFlipOutgoingOrientation}
          videoStreams={videoStreams}
          isRecording={isRecording}
          setIsRecording={setIsRecording}
          localAudioStreams={localAudioStreams}
          audioStreams={audioStreams}
          changeLayout={changeLayout}
          rotateLeft={rotateLeft}
          setLeftRotationHack={setLeftRotationHack}
          setRightRotationHack={setRightRotationHack}
          rotateRight={rotateRight}
          toggleScreenshare={toggleScreenshare}
          showScreenshareButton={!someoneIsSharing.peerId}
          screenShareOn={screenShareOn}
          localVideoStreams={localVideoStreams}
          setLocalVideoStreams={setLocalVideoStreams}
          setLocalAudioStreams={setLocalAudioStreams}
          setScreenShareOn={setScreenShareOn}
          videoOn={videoOn}
          setLayout={setLayout}
          setPinned={setPinned}
          setSolo={setSolo}
          layout={layout}
          setVideoOn={setVideoOn}
          videoDevices={videoDevices}
        /> */}
        {/* <DeviceSelector
          setVideoDevices={setVideoDevices}
          videoAssignedId={videoAssignedId}
          audioAssignedId={audioAssignedId}
          cameraReplaced={(track, stream) => {

          }}
        /> */}
        <br />
        {/* <LayoutButtons
          count={videoStreams.length}
          changeLayout={changeLayout}
          layout={layout}
        /> */}
        <br />
        <ModeButtons
          setBroadcaster={setBroadcaster}
          broadcaster={broadcaster}
          mode={mode}
          setMode={setMode}
        />
      </div>

      {mouseNotMoved && (
        <Layout
          solo={solo}
          flipLocal={flipLocal}
          labelMode={labelMode}
          setLabelMode={setLabelMode}
          audioAssignedId={audioAssignedId}
          //setVideoDevices={setVideoDevices}
          setVideoStreams={setVideoStreams}
          //videoDevices={videoDevices}
          setVideoAssignedId={setVideoAssignedId}
          audioState={audioState}
          setAudioState={setAudioState}
          session_creator={session_creator}
          flipOutgoingOrientation={flipOutgoingOrientation}
          setFlipOutgoingOrientation={setFlipOutgoingOrientation}
          videoStreams={videoStreams}
          isRecording={isRecording}
          setIsRecording={setIsRecording}
          localAudioStreams={localAudioStreams}
          audioStreams={audioStreams}
          changeLayout={changeLayout}
          rotateLeft={rotateLeft}
          setLeftRotationHack={setLeftRotationHack}
          setRightRotationHack={setRightRotationHack}
          rotateRight={rotateRight}
          toggleScreenshare={toggleScreenshare}
          showScreenshareButton={!someoneIsSharing.peerId}
          screenShareOn={screenShareOn}
          localVideoStreams={localVideoStreams}
          setLocalVideoStreams={setLocalVideoStreams}
          setLocalAudioStreams={setLocalAudioStreams}
          setScreenShareOn={setScreenShareOn}
          videoOn={videoOn}
          setLayout={setLayout}
          setPinned={setPinned}
          setSolo={setSolo}
          layout={layout}
          setVideoOn={setVideoOn}
          //videoDevices={videoDevices}
        />
      )}
      <PermissionModal
        videoPermissionError={videoPermissionError}
        setVideoPermissionError={setVideoPermissionError}
        audioPermissionError={audioPermissionError}
        setAudioPermissionError={setAudioPermissionError}
      />
      <AdminControls
        sendControlUpdateToNativeApp={sendControlUpdateToNativeApp}
        admin={admin}
        count={videoEls.length}
      />
    </div>
  );
}

export default JamkazamVideo;
